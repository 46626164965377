import React from "react"; 

export default () => {
  return (
    <div className="py-8 footer-bg">
      <footer className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4">
          <div>
            <a
              href="https://www.vortices.org/"
              className="logo flex flex-row items-center"
            >
              <img
                src="/images/vor.png"
                width="220"
                className="cursor-pointer"
                alt="VOR"
              />
            </a>
            
          </div>
          <div className="text-white leading-7 text-xs cursor-pointer flex flex-col">
            <a
              href="https://www.vortices.org/"
              className="font-Montserrat-ExtraBold uppercase"
            >
              Inicio
            </a>
            {/* <div>Project</div> */}
            <a href="https://www.vortices.org/el-sistema/">EL SISTEMA VÓRTICES</a>
            <a href="https://www.vortices.org/los-grupos-de-compra/">LOS GRUPOS DE COMPRA</a>
            {/* <div>Ecosystem</div> */}
            <a href="https://www.vortices.org/el-proceso-de-compra/">EL PROCESO DE COMPRA</a>
          </div>
          
          <div className="text-white leading-7 text-xs cursor-pointer flex flex-col">
            <a
              href="https://www.vortices.org/"
              className="font-Montserrat-ExtraBold uppercase"
            >
              El Token VOR
            </a>
            {/* <div>Project</div> */}
            <a href="https://www.vortices.org/nuestro-sistema-de-lanzamiento/">NUESTRO SISTEMA DE LANZAMIENTO</a>
            <a href="https://www.vortices.org/tokenomics/">TOKENOMICS</a>
            {/* <div>Ecosystem</div> */}
            <a href="https://www.vortices.org/pool-de-lanzamiento/">POOL DE LANZAMIENTO !!</a>
          </div>
          <div className="text-white leading-7 text-xs cursor-pointer flex flex-col">
            <a
              href="https://www.vortices.org/"
              className="font-Montserrat-ExtraBold uppercase"
            >
              Chacras
            </a>
            {/* <div>Project</div> */}
            <a href="https://www.vortices.org/agricultores/">AGRICULTORES</a>
            <a href="https://www.vortices.org/bioconsructores/">BIOCONSRUCTORES</a>
            {/* <div>Ecosystem</div> */}
            <a href="https://www.vortices.org/el-inversor-facilitador/">EL INVERSOR FACILITADOR</a>
          </div>
          
          
        </div>
        <div className="footer-copyright text-center py-3 text-white">
        <div>
          &copy; {new Date().getFullYear()} Derechos de autor: <a href="#"> vortices.org </a>
        </div>
      </div>
      </footer>
    </div>
  );
};
