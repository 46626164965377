
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Button from "./components/common/Button";
import Modal from "./components/common/Modal";
import Card from "./components/common/Card";
import Spinner from "./components/common/Spinner";
// import { initWeb3 } from "./utils.js";
import VorStake from "./contracts/VorStake.json";
import ERC20 from "./contracts/ERC20.json";
import BUSD from "./contracts/BUSD.json";
import fromExponential from "from-exponential";
import { ethers } from "ethers";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { providerOptions } from "./providerOptions";
import { getNetwork } from "@ethersproject/networks";

const web3Modal = new Web3Modal({
  network:"mainnet",
  cacheProvider: true, // optional
  providerOptions // required
});

function initWeb3(provider) {
	var web3 = new Web3(provider);

	web3.eth.extend({
		methods: [
			{
				name: "chainId",
				call: "eth_chainId",
				outputFormatter: web3.utils.hexToNumber
			}
		]
	});

	return web3;
}

const App = () => {
  const [loading, setLoading] = useState(false);
  const [stakeLoading, setStakeLoading] = useState(false);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [error, setError] = useState("");
  const [web3, setWeb3] = useState();
  const [accounts, setAccounts] = useState();
  const [vorStake, setVorStake] = useState();
  const [vorToken, setVorToken] = useState();
  const [busdToken, setBusdToken] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const [balance, setBalance] = useState();
  const [totalStaked, setTotalStaked] = useState();
  const [totalBusd, setTotalBusd] = useState();
  const [nextTime, setNextTime] = useState();
  const [nextClaimTime, setNextClaimTime] = useState();
  const [stakes, setStakes] = useState();
  const [registeredStatus, setRegisteredStatus]=useState();
  
  const [dailyROI, setDailyROI] = useState();
  
  const [totalRewards, setTotalRewards] = useState();
  const [amount, setAmount] = useState();
  const [unstakeAmount, setUnstakeAmount] = useState();  
  const [showModal, setShowModal] = useState(false);
  const [Bvalue, setValue] = useState("");
  const [releaseAmount, setReleaseAmount] = useState(0);

  const init = async () => {
    
    if (isReady()) {
      return;
    }
    

    
     const provider = await web3Modal.connect();
     const web3 = initWeb3(provider);

    // let web3;
    // try {
    //   web3 = await initWeb3(provider);
    // } catch (err) {
    //   console.error(err);
    //   setLoading(false);
    //   return;
    // }
    setLoading(true);
    

    const accounts = await web3.eth.getAccounts();    
    const networkId = await web3.eth.net.getId();    
    console.log(networkId);

    if (networkId !== 56) {
      setError("Por favor, conecte BSC Mainnet");
      setLoading(false);
      return;
    }
    const vorToken = new web3.eth.Contract(
      ERC20.abi,
      "0x73fdc3c5f4adc2d9728f8577affca143cc275b0a"
    ); 
    const busdToken = new web3.eth.Contract(
      BUSD.abi,
      "0xe9e7cea3dedca5984780bafc599bd69add087d56"
    ); 
    const totalSupply = await vorToken.methods.totalSupply().call();
    const balance = await vorToken.methods.balanceOf(accounts[0]).call();

    const vorStake = new web3.eth.Contract(
      VorStake.abi,
      "0xaa09afa24f8b8e4dc13df521c36e0b6712d8122b"
    ); //mainnet address for staking dapp
    const totalStaked = await vorStake.methods.totalStaked().call();
    const totalBusds = await vorStake.methods._amountOfBUSD().call();
    const dailyROI = await vorStake.methods.defaultAPY().call();
    const status = await vorStake.methods.hasStaked(accounts[0]).call();
    const releaseAmount= await vorStake.methods.getReleaseAmt(accounts[0]).call();
    const nextTime= await vorStake.methods.getNextReleaseTime(accounts[0]).call();
    const nextClaimTime= await vorStake.methods.getNextClaimTime(accounts[0]).call();
    
    setWeb3(web3);
    setAccounts(accounts);
    setVorStake(vorStake);
    setVorToken(vorToken);
    setBusdToken(busdToken);
    setTotalSupply(totalSupply);
    setBalance(balance);
    setTotalStaked(totalStaked) ;
    setTotalBusd(totalBusds); 
    setNextTime(nextTime);
    setNextClaimTime(nextClaimTime);
    setReleaseAmount(releaseAmount); 
    setDailyROI(dailyROI);
    setRegisteredStatus(status);

    provider.on("accountsChanged", async (accounts) => {
      setAccounts(accounts);
      
    });
    provider.on("chainChanged", async (networkId)=>{
      
      if(networkId!=56){
        alert ("Por favor, conecte BSC Mainnet")
        setError("Por favor, conecte BSC Mainnet");
        setLoading(false);
        return;
      }
    });
    const clearAccount = async () => {
      await web3Modal.clearCachedProvider();      
      setAccounts("");
      console.log('clearAccount');
    };
    provider.on("disconnect",clearAccount);

    setLoading(false);
  };

  const isReady = () => {
    return !!vorStake && !!web3 && !!accounts;
  };

  // useEffect(() => {
  //   const triggerAlreadyInjectedWeb3 = async () => {
      
  //     if (window.ethereum) {
  //       if (
  //         window.ethereum.selectedAddress &&
  //         window.ethereum.networkVersion === "56"
  //       ) {
          
  //         await init();
  //       }
  //     }
  //   };
  //   triggerAlreadyInjectedWeb3();
  // }, []);

 

  async function updateAll() {
    await Promise.all([
      updateStakes(),
      updateTotalSupply(),
      updateAccountBalance(),
      updateTotalStaked(),      
      updateTotalBusd(),
      updateReleaseAmt(),
      updateNextTime(),
      updateNextClaimTime(),
      totalReward(),      
      updateStatus()
    ]);
  }

  useEffect(() => {
    if (isReady()) {
      updateAll();
    }
    
  }, [vorStake, vorToken, busdToken,web3, accounts]);

  async function updateStakes() {
    const stake = await vorStake.methods.stakingBalance(accounts[0]).call();
    setStakes(stake);
    return stake;
  }



  async function updateAccountBalance() {
    if (vorToken) {
      const balance = await vorToken.methods.balanceOf(accounts[0]).call();
      setBalance(balance);
      return balance;
    }
  }

  async function updateTotalSupply() {
    if (vorToken) {
      const totalSupply = await vorToken.methods.totalSupply().call();
      setTotalSupply(totalSupply);
      return totalSupply;
    }
  }

  async function updateTotalStaked() {
    if (vorStake) {
      const totalStaked = await vorStake.methods.totalStaked().call();
      setTotalStaked(totalStaked);
      return totalStaked;
    }
  }

  async function updateTotalBusd() {
    if (vorStake) {
      const totalBusd = await vorStake.methods._amountOfBUSD().call();
      setTotalBusd(totalBusd);
      return totalBusd;
    }
  }
  async function updateReleaseAmt() {
    if (vorStake) {
      const releaseAmount = await vorStake.methods.getReleaseAmt(accounts[0]).call();
      setReleaseAmount(releaseAmount);
      return releaseAmount;
    }
  }
  async function updateNextTime() {
    if (vorStake) {
      const nextTime = await vorStake.methods.getNextReleaseTime(accounts[0]).call();
      setNextTime(nextTime)
      return nextTime;
    }
  }
  async function updateNextClaimTime() {
    if (vorStake) {
      const nextClaimTime = await vorStake.methods.getNextClaimTime(accounts[0]).call();
      setNextClaimTime(nextClaimTime)
      return nextClaimTime;
    }
  }




  async function totalReward() {
    const owing = parseInt(
      await vorStake.methods.calculateEarnings(accounts[0]).call()
    );
    const recorded = parseInt(
      await vorStake.methods.stakeRewards(accounts[0]).call()
    );
    const sum = owing +  recorded;
    await setTotalRewards(sum);
    return sum;
  }

  async function updateStatus() {
    if (vorToken) {
      const status = await vorStake.methods.hasStaked(accounts[0]).call();
      setRegisteredStatus(status);
    }
  }

  async function stake() {
    setStakeLoading(true);
    const actual = amount * 10 ** 9;
    const arg = fromExponential(actual);
    try {
      await vorToken.methods
        .approve("0xaa09afa24f8b8e4dc13df521c36e0b6712d8122b", arg)
        .send({ from: accounts[0] });

      await vorStake.methods.stakeTokens(arg).send({ from: accounts[0] });
      await updateAll();
    } catch (err) {
      if (err.code !== 4001) {
        setShowModal(true);
      }
      console.error(err);
    }
    setStakeLoading(false);
  }

  async function addBusd() {
    setStakeLoading(true);
    const actual = Bvalue*10**18;
    const arg = fromExponential(actual);
    const arg1 = fromExponential(Bvalue);
    try {
      await busdToken.methods
        .approve("0xaa09afa24f8b8e4dc13df521c36e0b6712d8122b", arg)
        .send({ from: accounts[0] });

      await vorStake.methods.buyTokensWithBUSDforLp(arg1).send({ from: accounts[0] });
      await updateAll();
    } catch (err) {
      if (err.code !== 4001) {
        setShowModal(true);
      }
      console.error(err);
    }
    setStakeLoading(false);
  }

  async function unstake() {
    if (parseFloat(stakes) === 0) {
      console.error("You don't have any staked VORs yet!");
      return;
    }
    setUnstakeLoading(true);
    const actual = unstakeAmount;
    const arg = fromExponential(actual);
    try {
      await vorStake.methods.unstakeTokens(arg).send({ from: accounts[0] });
      await updateAll();
    } catch (err) {
      if (err.code !== 4001) {
        setShowModal(true);
      }
      console.error(err);
    }
    setUnstakeLoading(false);
  }

  async function withdrawEarnings() {
    if (parseFloat(totalRewards) === 0) {
      console.error("No earnings yet!");
      return;
    }
    setWithdrawLoading(true);
    try {
      await vorStake.methods.withdrawEarnings().send({ from: accounts[0] });
      await updateAll();
    } catch (err) {
      if (err.code !== 4001) {
        setShowModal(true);
      }
      console.error(err);
    }
    setWithdrawLoading(false);
  }

  return (
    <div className="w-full overflow-hidden">
      {showModal && (
        <Modal title="" onClose={() => setShowModal(false)}>
          <div className="text-2xl mb-2">
            Error! Your transaction has been reverted!
          </div>
          <div>Please check your account and retry again...</div>
          

          <div className="my-2">
            Thanks for your support and feel free to{" "}
            <a href="https://www.vortices.org/contacto/" className="text-blue-500">
              contact us
            </a>
          </div>

          <div className="flex flex-row justify-center">
            <Button onClick={() => setShowModal(false)}>Close</Button>
          </div>
        </Modal>
      )}


      <div className="relative z-10 w-full top-0">
        <div className="absolute w-full home-gradient"></div>
      </div>

      <div className="relative w-full z-30 main-bg">
        <Header />

        <div className="container mx-auto pb-18 px-4 force-height">
          {!accounts && (
            <div className="w-full py-6 text-center">
                 
              <Button
                className="w-full md:w-2/5 text-2xl flex flex-row justify-center mx-auto"
                uppercase={false}
                onClick={async () => await init()}
              >
                {loading && <Spinner color="white" size={40} />}
                {!loading && (error !== "" ? error : "Conecta tu WALLET")}
              </Button>

              <div className="text-white text-center mt-6 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold">
                <h1>STAKEA tus VORS - BUSD</h1>
              </div>
              <div className="w-full md:w-3/6 justify-center mx-auto mt-6">
                <Card title="INSTRUCCIONES">
                  <div className="flex flex-col pt-8 pb-4 text-white text-center">
                  <ul>
                      <li>1. ¡Conecta tu monedero Wallet para participar!</li>
                      <li>2. ¡Agrega BUSD para obtener VOR para STAKE!</li>
                      <li>                      
                      3. ¡Stakea VOR y gana RECOMPENSAS todos los meses! 
                      </li>
                      <li>4. ¡Retira tus RECOMPENSAS en cualquier momento!</li>
                      <li>5. ¡Retira o Reinvierte tus VORS cuando quieras!</li>
                      
                    </ul>
                  </div>
                </Card>
                <div className="flex flex-col pt-8 px-2">
                  <br />
                  <a  href="https://github.com/interfinetwork/smart-contract-audits/blob/audit-updates/VO%CC%81RTICES_AuditReport_InterFi.pdf" target="_blank">
                  <img
                src="/images/Audit.jpg"                
                className="cursor-pointer"                
              />
                  </a>
                  
                  <br />
                </div>
                <Card noLine>
                  <div className="flex flex-col px-2">
                    <div className="text-center pb-4">
                      <div className="text-white text-xs">
                        <span className="text-blue-500">NOTA:  </span>{"    "}
                        Agrega BUSD en nuestro FONDO DE LIQUIDEZ y obtendrás VORS a precio inicial !! (STAKE 5 años. Liberación 20% anual + 0.25% de recompensas mensuales !!).<br/>
                        Podrás usar VORS para adquirir tu ESPACIO VITAL de 1 há en cualquiera de nuestros VÓRTICES !!<br/>
                        Más información en {"    "}
                        <a
                          href="https://www.vortices.org/"
                          target="_blank"
                          className="text-blue-500"
                        >
                          vortices.org
                        </a>
                        
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          )}
          {!!accounts && (
            <div>
              <div className=" elementor-column presaleWidget" style={{ marginBottom: 30 }}>
                            <a href="https://bscscan.com/address/0x73fdc3c5f4adc2d9728f8577affca143cc275b0a" target="_blank"className="gotoWallet">
                            0x73fdc3c5f4adc2d9728f8577affca143cc275b0a
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="16px" width="16px" viewBox="0 0 24 24" className="sc-16r8icm-0 coGWQa">
                                    <path d="M12 11.9998L20 4M20 4H14.1817M20 4L19.9999 9.81802M9.81819 6.90946H5.77777C4.79594 6.90946 4 7.70537 4 8.68718V18.2223C4 19.2041 4.79594 20 5.77778 20H15.3131C16.295 20 17.0909 19.2041 17.0909 18.2223V14.182" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                                    </path>
                                </svg>
                            </a>
                            <div className="swapContent">
                                <div className="swapTitle">
                                    <div>
                                    Cantidad recaudada en BUSD:
                                    </div>
                                    &nbsp;&nbsp;{
                          (parseInt(totalBusd))}
                                </div>
                                <div className="tokenContent">
                                    <div className="tokenImg">
                                        <img src={process.env.PUBLIC_URL + "assets/images/BUSD.png"} />
                                    </div>
                                    <div className="tokenName">BUSD</div>
                                    <div className="tokenValue">
                                        <input onChange={ e => setValue(e.target.value)} type="text"  />
                                    </div>
                                </div>

                                <div className="tokenContent tokenContent1">
                                    <div className="tokenImg">
                                        <img src={process.env.PUBLIC_URL + "assets/images/vor.png"} />
                                    </div>
                                    <div className="tokenName">VOR</div>
                                    <div className="tokenValue">
                                        <input value={Bvalue*250} type="text"  />
                                    </div>
                                </div>
                                <div className="exchangeBtn">
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="35" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <polyline points="5 12 12 5 19 12"></polyline>
                                        <line x1="12" y1="5" x2="12" y2="35"></line>
                                    </svg>
                                </div>
                                <div className="swapBtn" onClick={addBusd} >Intercambio</div>
                            </div>
                        </div>
              <div className="grid grid-col-1 md:grid-cols-2 gap-6 mt-10">
                <Card title="TOTAL DE VORs EN STAKE">
                  <div className="flex flex-col pt-8 pb-4 text-white">
                    <div className="text-center">
                      <span className="text-white text-5xl">
                        {(
                          parseFloat(totalStaked).toFixed(2) / 1000000000
                        ).toFixed(2)}
                      </span>
                      <span className="text-white text-2xl ml-2">VOR</span>
                    </div>
                    <div className="text-center">
                      {(
                        (parseFloat(totalStaked) * 100.0) /
                        parseFloat(totalSupply)
                      ).toFixed(5)}
                      %
                    </div>
                    <div className="text-center">de la oferta total</div>
                  </div>
                </Card>
                {!registeredStatus ? (
                  <Card title="TUS VORS EN STAKE">
                    <div className="flex flex-col pt-8 px-2">
                      <div className="text-center pb-4">
                      
                            <span className="text-white">
                            Recompensa Mensual :{" "}
                            </span>
                            <span className="text-white text-3xl">
                            {parseFloat(dailyROI) / 100} %
                            </span>
                          
                      </div>
                      <div className="text-center pb-4">
                        <span className="text-white">
                        Tus VORS en Stake:{" "}
                        </span>
                        <span className="text-white text-3xl">
                          {parseInt(parseInt(stakes) / 1000000000)}
                        </span>
                        <span className="text-white text-2xl ml-2">VOR</span>
                      </div>
                      <div className="text-center pb-4">
                        <span className="text-white">
                        Monto disponible:{" "}
                        </span>
                        <span className="text-white text-3xl">
                          {parseInt(parseInt(balance) / 1000000000)}
                        </span>
                        <span className="text-white text-2xl ml-2">VOR</span>
                      </div>
                      <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                        <input
                          type="number"
                          placeholder="VOR Para Stakear"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          className="text-white font-extrabold flex-shrink text-2xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                        />
                        <Button
                          onClick={() => stake()}
                          className="flex flex-row items-center w-48 justify-center"
                        >
                          {stakeLoading ? (
                            <Spinner size={30} />
                          ) : (
                            <>
                              <img src="/images/locked.svg" width="25" alt="" />
                              <span className="w-16">STAKEAR</span>{" "}
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Card>
                ) : (
                  <Card title="TUS VORS EN STAKE">
                    <div className="flex flex-col pt-8 px-2">
                      
                      <div className="text-center pb-4">
                        
                        <span className="text-white">
                        Recompensa Mensual :{" "}
                        </span>
                        <span className="text-white text-3xl">
                        {parseFloat(dailyROI) / 100} %
                        </span>
                      
                      </div>
                      <div className="text-center pb-4">
                        <span className="text-white">
                        Tus VORS en Stake:{" "}
                        </span>
                        <span className="text-white text-3xl">
                          {parseInt(parseInt(stakes) / 1000000000)}
                        </span>
                        <span className="text-white text-2xl ml-2">VOR</span>
                      </div>
                      <div className="text-center pb-4">
                        <span className="text-white">
                        Monto disponible:{" "}
                        </span>
                        <span className="text-white text-3xl">
                          {parseInt(parseInt(balance) / 1000000000)}
                        </span>
                        <span className="text-white text-2xl ml-2">VOR</span>
                      </div>
                      <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                        <input
                          type="number"
                          placeholder="VOR Para Stakear"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          className="text-white font-extrabold flex-shrink text-2xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                        />
                        <Button
                          onClick={() => stake()}
                          className="flex flex-row items-center w-48 justify-center"
                        >
                          {stakeLoading ? (
                            <Spinner size={30} />
                          ) : (
                            <>
                              <img src="/images/locked.svg" width="25" alt="" />
                              <span className="w-16">STAKEAR</span>{" "}
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Card>
                )}
                <Card title="TUS GANANCIAS">
                  <div className="flex flex-col pt-8 px-2">
                    {!!registeredStatus&&(<div className="text-center pb-4">
                      <span className="text-white">
                      Tiempo que falta para la próxima liberación de recompensas:{" "}
                      </span>
                      <span className="text-white text-3xl">
                        {(parseFloat(nextClaimTime) ).toFixed()}
                      </span>
                      <span className="text-white text-2xl ml-2">DIAS</span>
                    </div>)}
                    <div className="text-center pb-8">
                      <span className="text-white text-5xl">
                        {(parseFloat(totalRewards) / 1000000000).toFixed(
                          2
                        )}
                      </span>
                      <span className="text-white text-2xl ml-2">VOR</span>
                    </div>
                    
                    <div className="flex flex-row justify-center">
                      <Button
                        type="submit"
                        className="flex flex-row items-center justify-center w-32"
                        onClick={() => withdrawEarnings()}
                      >
                        {withdrawLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            <img src="/images/unlocked.svg" width="25" alt="" />
                            <span className="w-24">RECOMPENSAS</span>{" "}
                          </>
                        )}
                      </Button>
                    </div>
                    
                  </div>
                </Card>
                <Card title="VORS PARA DESBLOQUEAR">
                  <div className="flex flex-col pt-8 px-2">
                    {!!registeredStatus&&(<div className="text-center pb-4">
                      <span className="text-white">
                      Tiempo que resta para la próxima~liberación:{" "}
                      </span>
                      <span className="text-white text-3xl">
                        {(parseFloat(nextTime) ).toFixed()}
                      </span>
                      <span className="text-white text-2xl ml-2">DIAS</span>
                    </div>)}
                    <div className="text-center pb-4">
                      <span className="text-white">
                      Disponible para retirar:{" "}
                      </span>
                      <span className="text-white text-3xl">
                        {(parseFloat(releaseAmount) / 1000000000).toFixed()}
                      </span>
                      <span className="text-white text-2xl ml-2">VOR</span>
                    </div>
                    <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                      <input
                        type="number"
                        placeholder="VORS Para Desbloquear"
                        value={unstakeAmount}
                        onChange={(e) => setUnstakeAmount(e.target.value)}
                        className="text-white font-extrabold flex-shrink text-2xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                      />
                      <Button
                        onClick={() => unstake()}
                        className="flex flex-row items-center w-48 justify-center"
                      >
                        {unstakeLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            <img src="/images/unlocked.svg" width="25" alt="" />
                            <span className="w-24">DESBLOQUEAR</span>
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
