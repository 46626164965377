import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";


export const providerOptions = {
  // walletlink: {
  //   package: CoinbaseWalletSDK, // Required
  //   options: {
  //     appName: "VOR", // Required
  //     infuraId: "a364b3c14ec24d67a8d260b721adb45b" // Required unless you provide a JSON RPC url; see `rpc` below
  //   }
  // },
  walletconnect: {
    package: WalletConnectProvider,
    			options: {
    				infuraId: "a364b3c14ec24d67a8d260b721adb45b"
    			}
  }
};
